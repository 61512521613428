<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="false"
    :section="section"
  >
    <template #section>
      <v-card-text
        v-if="APPLICATION?.data"
        class="mt-4"
      >
        <div class="mb-5">
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Value
              </th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>Property Value</td>
              <td>${{ propertyValue }}</td>
            </tr>
            <tr>
              <td>Lending Required</td>
              <td>${{ lendingRequired }}</td>
            </tr>
            <tr>
              <td>Deposit</td>
              <td>${{ deposit }}
              </td>
            </tr>
            <tr>
              <td>LVR</td>
              <td>
                {{ LVR }}%
              </td>
            </tr>
            <tr>
              <td>Total Monthly Income</td>
              <td>${{ totalMonthlyIncome }}
              </td>
            </tr>
            <tr>
              <td>Total Monthly Expenses and Commitments</td>
              <td>${{ totalMonthlyExpensesAndCommitments }}</td>
            </tr>
            <tr>
              <td>Actual Uncommitted Monthly Income</td>
              <td>${{ actualUncommittedMonthlyIncome }}</td>
            </tr>
            <tr>
              <td>Stressed Uncommitted Income (UMI)</td>
              <td>${{ UMI }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import SectionMixin from '@/mixins/SectionMixin';
import SummaryMixin from '@/mixins/SummaryMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'SummarySection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin, SummaryMixin],

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.$emit('componentReady');
  },
};
</script>
