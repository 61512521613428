var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": false,
      "section": _vm.section
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        var _vm$APPLICATION;

        return [(_vm$APPLICATION = _vm.APPLICATION) !== null && _vm$APPLICATION !== void 0 && _vm$APPLICATION.data ? _c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-simple-table', [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Name ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Value ")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Property Value")]), _c('td', [_vm._v("$" + _vm._s(_vm.propertyValue))])]), _c('tr', [_c('td', [_vm._v("Lending Required")]), _c('td', [_vm._v("$" + _vm._s(_vm.lendingRequired))])]), _c('tr', [_c('td', [_vm._v("Deposit")]), _c('td', [_vm._v("$" + _vm._s(_vm.deposit) + " ")])]), _c('tr', [_c('td', [_vm._v("LVR")]), _c('td', [_vm._v(" " + _vm._s(_vm.LVR) + "% ")])]), _c('tr', [_c('td', [_vm._v("Total Monthly Income")]), _c('td', [_vm._v("$" + _vm._s(_vm.totalMonthlyIncome) + " ")])]), _c('tr', [_c('td', [_vm._v("Total Monthly Expenses and Commitments")]), _c('td', [_vm._v("$" + _vm._s(_vm.totalMonthlyExpensesAndCommitments))])]), _c('tr', [_c('td', [_vm._v("Actual Uncommitted Monthly Income")]), _c('td', [_vm._v("$" + _vm._s(_vm.actualUncommittedMonthlyIncome))])]), _c('tr', [_c('td', [_vm._v("Stressed Uncommitted Income (UMI)")]), _c('td', [_vm._v("$" + _vm._s(_vm.UMI))])])])])], 1)]) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }